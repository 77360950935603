// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

require('jquery');


Rails.start()
Turbolinks.start()
ActiveStorage.start()



// import tippy from 'tippy.js';
// window.tippy = tippy;


import toastr from 'toastr';
window.toastr = toastr;


import FroalaEditor from 'froala-editor/js/froala_editor.pkgd.min.js';
window.FroalaEditor = FroalaEditor;


// bootstrapを組み込む場合
// import * as bootstrap from 'bootstrap'
// import "../stylesheets/application.scss"

// import "./bootstrap_template/soft-ui-dashboard.min"
// import "./bootstrap_template/chartjs.min"
// import "./bootstrap_template/perfect-scrollbar.min"
// import "./bootstrap_template/smooth-scrollbar.min"
// import "./bootstrap_template/soft-ui-dashboard.min"

// document.addEventListener("DOMContentLoaded", function(event) {
//   var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
//   var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
//     return new bootstrap.Popover(popoverTriggerEl)
//   })

//   var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
//   var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
//     return new bootstrap.Tooltip(tooltipTriggerEl)
//   })
// });